.react-multiple-carousel__arrow{
    color: #CFD0D4 !important;
    background-color: white;
}
.react-multiple-carousel__arrow:hover{
    color: #CFD0D4 !important;
    background-color: white;
}
.react-multiple-carousel__arrow:before{
    color: #CFD0D4 !important;
}

.react-multiple-carousel__arrow--left{
    left: 0 !important;
}
.react-multiple-carousel__arrow--right{
    right: 0;
}